// Apine
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

// Plugins
import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

// Extend webpack window classes
window.Swiper = Swiper

// Initialize alpine
Alpine.plugin(intersect)

// Add to window
window.Alpine = Alpine;
window.Alpine.start();